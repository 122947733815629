@font-face {
    font-family: 'Montserrat Regular';
    src: url(../fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: 'Montserrat Medium';
    src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
    font-family: 'Montserrat Semibold';
    src: url(../fonts/Montserrat-SemiBold.ttf);
}

@font-face {
    font-family: 'Montserrat Bold';
    src: url(../fonts/Montserrat-Bold.ttf);
}
